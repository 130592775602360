import React, { useState, useEffect } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Footer from "../components/footer"
import Instagram_logo from "../images/instagram.svg"
import Instagram from "../images/instagram.svg"
import Facebook from "../images/facebook.svg"

function converIGtoJPG(base64data) {
  const jpegtpl =
    "/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEABsaGikdKUEmJkFCLy8vQkc/Pj4/R0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0cBHSkpNCY0PygoP0c/NT9HR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR0dHR//AABEIABQAKgMBIgACEQEDEQH/xAGiAAABBQEBAQEBAQAAAAAAAAAAAQIDBAUGBwgJCgsQAAIBAwMCBAMFBQQEAAABfQECAwAEEQUSITFBBhNRYQcicRQygZGhCCNCscEVUtHwJDNicoIJChYXGBkaJSYnKCkqNDU2Nzg5OkNERUZHSElKU1RVVldYWVpjZGVmZ2hpanN0dXZ3eHl6g4SFhoeIiYqSk5SVlpeYmZqio6Slpqeoqaqys7S1tre4ubrCw8TFxsfIycrS09TV1tfY2drh4uPk5ebn6Onq8fLz9PX29/j5+gEAAwEBAQEBAQEBAQAAAAAAAAECAwQFBgcICQoLEQACAQIEBAMEBwUEBAABAncAAQIDEQQFITEGEkFRB2FxEyIygQgUQpGhscEJIzNS8BVictEKFiQ04SXxFxgZGiYnKCkqNTY3ODk6Q0RFRkdISUpTVFVWV1hZWmNkZWZnaGlqc3R1dnd4eXqCg4SFhoeIiYqSk5SVlpeYmZqio6Slpqeoqaqys7S1tre4ubrCw8TFxsfIycrS09TV1tfY2dri4+Tl5ufo6ery8/T19vf4+fr/2gAMAwEAAhEDEQA/AA=="
  const t = atob(base64data)
  const p = t.slice(3).split("")
  const o = t
    .substring(0, 3)
    .split("")
    .map(e => e.charCodeAt(0))
  const c = atob(jpegtpl).split("")
  c[162] = String.fromCharCode(o[1])
  c[160] = String.fromCharCode(o[2])
  return base64data
    ? `data:image/jpeg;base64,${btoa(c.concat(p).join(""))}`
    : null
}

function useInstagram() {
  const [posts, setPosts] = useState([])
  useEffect(() => {
    fetch(`/.netlify/functions/instagram`)
      .then(res => res.json())
      .then(data => {
        console.log("Data ", data)
        setPosts(data)
      })
  }, [])
  return posts
}

export default function Novedades() {
  const gramz = useInstagram()

  return (
    <Layout>
      <SEO title="Novedades de Spiritual Worker" />
      {/* start instagram feed */}

      <div className="w-full mx-auto bg-gray-900 pt-5 pt-10 pb-10 lg:pt-20 lg:pb-20">
        <h2 className="titles text-gray-200 text-2xl lg:text-4xl text-center pb-5 lg:pb-20">
          Novedades
        </h2>
        <div className="w-full lg:w-10/12 lg:flex mx-auto">
          <div className="hidden md:block w-full lg:w-1/3 rounded-lg p-5">
            <p className="text-yellow-100 p-0 pt-3">
              <div className="social-media">
                <h3 className="text-base">Seguí a Spiritual Worker:</h3>
                <a
                  href="https://www.instagram.com/spiritualworker"
                  target="_blank"
                  rel="noopener"
                  className="block m-3 mt-10 ml-0"
                  style={{
                    backgroundImage: `url(${Instagram})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    paddingLeft: "40px",
                  }}
                >
                  @SpiritualWorker
                </a>
                <a
                  href="https://www.facebook.com/Gisellespiritualworker"
                  target="_blank"
                  rel="noopener"
                  className="block m-3 ml-0"
                  style={{
                    backgroundImage: `url(${Facebook})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    paddingLeft: "40px",
                  }}
                >
                  /Gisellespiritualworker
                </a>
              </div>
            </p>
          </div>

          <div className="w-full lg:w-2/3 mt-5 lg:mt-0">
            <div>
              {!gramz.length && (
                <p className="texts text-sm">Cargando los posts...</p>
              )}

              {gramz.length ? (
                <h4></h4>
              ) : (
                <p className="texts text-sm">
                  Error cargando posts de instagram
                </p>
              )}
              <div>
                {Array.isArray(gramz) &&
                  gramz.map(gram => (
                    <div className="isntapost block my-10">
                      <a href={gram.url} key={gram.id}>
                        <img
                          src={`https://images.weserv.nl/?url=${encodeURIComponent(
                            gram.biggie
                          )}`}
                          alt={gram.caption}
                          className="p-3"
                        />
                      </a>
                      <p className="texts w-11/12 text-base">{gram.caption}</p>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* end instagram feed */}

      <Footer />
    </Layout>
  )
}
